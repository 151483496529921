<template>
    <el-dialog
      :visible.sync="showDialog"
      title="分配菜单"
      width="900px"
      :modal-append-to-body="false"
      style="text-align:left;"
      @close="closeDialog"
      :close-on-click-modal="false"
    >
  <div class="role-permission">
    <el-transfer
      v-model="relatedMenus"
      :data="menuList"
      v-loading="loading"
      filterable
      :filter-method="filterMethod"
      filter-placeholder="请输入关键字"
      :props="{key: 'id',label: 'description'}"
      :titles="['未分配菜单','已分配菜单']"
    ></el-transfer>
  </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
</template>
<script>
import menuApi from "@/api/sys/menu";
import permissionApi from "@/api/sys/permission";
import roleApi from "@/api/sys/role";

export default {
  props: ["roleId"],
  data() {
    return {
      menuList: [],
      relatedMenus: [],
      loading: false,
      showDialog: true,
      submitting: false
    };
  }, 
  methods: {
    closeDialog() {
      this.$emit("close",false);
    },
    handleSubmit() {
      var self = this;

      roleApi
        .saveRelatedMenu({
          roleId: this.roleId,
          relatedList: this.relatedMenus
        })
        .then(function(response) {
          var jsonData = response.data;
          self.submitting = false;

          if (jsonData.result) {
            self.$message({
              message: "保存成功!",
              type: "success"
            });

            self.$emit("close",true);
          } else {
            self.$message({
              message: jsonData.message + "",
              type: "warning"
            });

            self.$emit("close",false);
          }
        });
    },
    filterMethod(query, item) {
      return item.description.indexOf(query) > -1;
    },
    queryRelatedMenuList() {
      var self = this;

      self.loading = true;

      self.menuList = [];
      self.relatedMenus= [];

      var queryData = new FormData();
      queryData.append("limit",1000);

      menuApi.query(queryData)
        .then(response => {
          var jsonData = eval(response.data);

          self.menuList = jsonData.data.map(item => {
            var description = "";

            if(item.parentName!=null){
              description = item.parentName + ">";
            }

            description += item.menuName;

            return {
              id: item.id,
              description: description
            };
          });

          return roleApi.queryRelatedMenuList(self.roleId);
        })
        .then(response => {
          var jsonData = response.data;

            self.relatedMenus = jsonData.data.map(item => {
            return item.menuId;
          });

          self.loading = false;
        });
    }
  },
  mounted() {
    this.queryRelatedMenuList();
  }
};
</script>
<style lang="scss">
.role-permission {
  .el-transfer-panel {
    border: 1px solid #ebeef5;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    width: 320px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }
}
</style>
