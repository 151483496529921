<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
    <el-dialog
      :visible.sync="showModal"
      :title="modalTitle"
      :modal-append-to-body="false"
      style="text-align:left;"
      :close-on-click-modal="false"
    >
  <div class="user-panel" v-loading="loading">
    <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
      <el-form-item label="角色名称" prop="name">
        <el-input v-model="formModel.name" placeholder="请输入角色名称" style="width:300px"></el-input>
      </el-form-item>
      <el-form-item label="角色描述" prop="description">
        <el-input v-model="formModel.description" placeholder="请输入角色描述" style="width:300px"></el-input>
      </el-form-item>
    </el-form>
  </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
</template>
<script>
import Constant from "@/constant";
import roleApi from "@/api/sys/role";
import NProgress from "nprogress"; // progress bar

export default {
  props: ["roleId","modalTitle"],
  data() {
    return {
      showModal: true,
      loading: false,
      submitting: false,
      formModel:{},
      ruleValidate: {
        name: [
          { required: true, message: "角色名称不能为空", trigger: "blur" }
        ],
        description: [
          { required: true, message: "角色描述不能为空", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    handleCancel() {
      this.$emit("close",false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate(valid => {
        if (valid) {
          (function() {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return roleApi.add(self.formModel);
            } else {
              return roleApi.update(self.formModel);
            }
          })().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success"
              });

              self.$emit("close",true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning"
              });

              self.$emit("close",false);
            }
          });
        }
      });
    }
  },
  mounted: function() {
      var self = this;

      self.loading = true;

      (function(){
        if(self.roleId==null || self.roleId.length==0){
          return roleApi.create();
        }
        else{
          return roleApi.edit(self.roleId);
        }
      })().then(response => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch(error => {
        self.loading = false;

        self.$message.error(error + "");
      });
  }
};
</script>